<template>
  <div class="page">
    <van-nav-bar title="设备数据" fixed></van-nav-bar>

    <van-pull-refresh class="refresh" v-model="refreshing" @refresh="onRefresh">

      <van-cell title="选择日期区间" :value="date" @click="show = true" is-link style="margin: 8px 0" />
      <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" :default-date="defaultDate" :allow-same-day="true" />

      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <van-cell v-for="item in list" :key="item.deviceId" :title="item.deviceName" is-link center @click="clickCell(item)">
          <template #label>
            <div style="display: flex;align-items: center">
              <span style="margin-right: 10px">订单数：<span style="color: #333">{{ item.orders }}</span></span>
              <span style="margin-right: 10px">增长率：<span :style="{ color: Number.parseFloat(item.increase) > 0 ? 'red' : 'green' }">{{ item.increase }}</span></span>
              <span style="margin-right: 10px">使用率：<span style="color: #333">{{ item.usage }}</span></span>
            </div>
          </template>
        </van-cell>
      </van-list>
    </van-pull-refresh>

  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    let date = new Date()
    let startDate = `${date.getFullYear()}-${date.getMonth() + 1}-1`
    let endDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    let minDate = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    let maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      date: `${startDate.substr(5)}/${endDate.substr(5)}`,
      show: false,
      form: { startDate, endDate },
      minDate,
      maxDate,
      defaultDate: [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth(), date.getDate())]
    }
  },
  created() {
    let date = new Date()
    this.form.startDate = this.formatForServer(new Date(date.getFullYear(), date.getMonth(), 1))
    this.form.endDate = this.formatForServer(date)
  },
  methods: {
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      console.log(start, end)
      this.show = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.form.startDate = this.formatForServer(start)
      this.form.endDate = this.formatForServer(end)
      this.onLoad()
    },
    formatForServer(date) {
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      console.log(year, month, day)
      return `${year < 10 ? ('0' + year) : year}-${month < 10 ? ('0' + month) : month}-${day < 10 ? ('0' + day) : day}`
    },

    onRefresh() {
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      if (!this.refreshing) this.refreshing = true
      this.$http.get('data/dataSheet', {params: this.form}).then(res => {
        this.loading = false
        this.finished = true
        this.refreshing = false
        if (res.code === 200) {
          /*res.data.sort((a, b) => {
            if (a.status === 'ONLINE' && b.status === 'ONLINE') return 0
            if (a.status === 'ONLINE' && b.status === 'OFFLINE') return -1
            if (a.status === 'OFFLINE' && b.status === 'ONLINE') return 1
          })*/
          this.list = res.data
        } else {
          if (res.code === 403) {
            this.$toast.fail('登录信息过期，请重新登录')
            this.$bus.$emit('login')
          }
        }
      }).catch(() => {
        this.loading = false
        this.refreshing = false
        this.finished = true
      })
    },

    clickCell(cabinet) {
      this.$router.push({
        path: '/deviceData',
        query: {deviceId: cabinet.deviceId, resellerId: cabinet.resellerId, partnerId: cabinet.partnerId}
      })
    }
  }
}
</script>

<style scoped>
/deep/ .van-list__finished-text {
  /*margin-bottom: 100px;*/
  padding-bottom: 100%;
  background-color: whitesmoke;
}
.refresh {
  margin-top: 46px;
  /*padding-bottom: 100px;*/
}
</style>
